import AppSource from '../data/app-source';
import { paymentDataDummy } from '../utils/dummy';
import formatRupiah from '../utils/format-rupiah';
import {
  buttonFilled, cardAccPayment, cardReqPayment, inputWIconTrailingCircle,
  loadPage,
} from '../views/templates/components';
import { iconChevronLeft, iconChevronRight, iconSearch } from '../views/templates/icons';

const HomeHandler = {
  init({
    homeContainer,
    changeRoleBtn,
    homeUserRole,
    username,
    containerActionPayment,
    containerRequestOnly,
    // month,
    // year,
    requestOnlyComponent,
    containerLoad,
  }) {
    const _userRole = localStorage.getItem('USER_ROLE');
    const _userHome = localStorage.getItem('USER_HOME');
    const _userName = localStorage.getItem('USER_NAME');
    const _userNim = localStorage.getItem('USER_NIM');
    const _userToken = localStorage.getItem('USER_TOKEN');
    // localStorage.setItem('MONTH', new Date().getMonth() + 1);
    // localStorage.setItem('YEAR', new Date().getFullYear());
    const _reqOnly = localStorage.getItem('REQ_ONLY');
    const _page = localStorage.getItem('PAGE');
    localStorage.setItem('REQ_ONLY', _reqOnly ?? false);
    localStorage.setItem('PAGE', _page ?? 1);
    if (!_userToken) location.assign('#/login');
    else {
      const usernameSplit = _userName.split(' ');
      username.innerHTML = usernameSplit.length > 1 ? `${usernameSplit[0]} ${usernameSplit[1]}` : usernameSplit;
      if (_userHome === '2') {
        homeUserRole.textContent = 'Officer';
        this._componentReqOnly({
          _userToken,
          _userHome,
          homeContainer,
          containerRequestOnly,
          requestOnlyComponent,
          containerLoad,
        });
        this._componentSearch({
          _userToken, _userHome, homeContainer, containerActionPayment, containerLoad,
        });
      } else if (_userHome === '1') {
        homeUserRole.textContent = _userNim;
        this._componentReqButton(containerActionPayment);
      } else this._error(homeContainer);
      // this._componentChangeRole(_userRole, _userHome, changeRoleBtn);
      // this._componentMonth(_userToken, _userHome, homeContainer, month, containerLoad);
      // this._componentYear(_userToken, _userHome, homeContainer, year, containerLoad);
      this._insertPayment(_userToken, _userHome, homeContainer, containerLoad);
    }
  },

  _componentSearch({
    _userToken, _userHome, homeContainer, containerActionPayment, containerLoad,
  }) {
    containerActionPayment.innerHTML = inputWIconTrailingCircle('search-payment-input', 'search', 'Search by NIM', iconSearch);
    const searchPaymentInput = homeContainer.querySelector('#search-payment-input input');
    // ----------- >>> alternative button for searching <<< -----------
    // const searcBtn = containerActionPayment.querySelector('#search-payment-input #search-btn');
    // searcBtn.addEventListener('click', () => {
    //   const query = searchPaymentInput.value;
    //   this._searchPayment(_userToken, _userHome, homeContainer, query, containerLoad);
    // });
    searchPaymentInput.addEventListener('change', () => {
      const reqOnlyCheckbox = homeContainer.querySelector('#req-only');
      localStorage.setItem('REQ_ONLY', false);
      reqOnlyCheckbox.removeAttribute('checked');
      const query = searchPaymentInput.value;
      if (query.length > 0) {
        this._searchPayment(_userToken, _userHome, homeContainer, query, containerLoad);
      } else location.reload();
    });
  },

  _componentReqOnly({
    _userToken, _userHome, homeContainer, containerRequestOnly, requestOnlyComponent, containerLoad,
  }) {
    containerRequestOnly.innerHTML = requestOnlyComponent;
    const reqOnlyCheckbox = homeContainer.querySelector('#req-only');
    if (localStorage.getItem('REQ_ONLY') === 'true') reqOnlyCheckbox.setAttribute('checked', 'checked');
    reqOnlyCheckbox.addEventListener('change', () => {
      const searchPaymentInput = homeContainer.querySelector('#search-payment-input input');
      searchPaymentInput.value = '';
      localStorage.setItem('PAGE', 1);
      localStorage.setItem('REQ_ONLY', reqOnlyCheckbox.checked);
      this._insertPayment(_userToken, _userHome, homeContainer, containerLoad);
    });
  },

  _componentReqButton(containerActionPayment) {
    containerActionPayment.innerHTML = buttonFilled('request-payment-btn', 'Create Request', 'mb-5');
    const requestPaymentBtn = containerActionPayment.querySelector('#request-payment-btn');
    requestPaymentBtn.addEventListener('click', () => location.assign('#/create-request'));
  },

  _disabledReqButton(_userHome, homeContainer) {
    if (_userHome === '1') {
      const requestPaymentBtn = homeContainer.querySelector('#request-payment-btn');
      requestPaymentBtn.setAttribute('disabled', 'disabled');
      requestPaymentBtn.classList.remove('bg-[#050C9C]');
      requestPaymentBtn.classList.add('bg-[#DCDCDC]');
    }
  },

  _componentChangeRole(_userRole, _userHome, changeRoleBtn) {
    // if (_userRole === '1') changeRoleBtn.remove();
    const onChange = () => {
      if (_userRole === '2') {
        const home = _userHome === '2' ? '1' : '2';
        localStorage.setItem('USER_HOME', home);
        location.reload();
      } else { return null; }
    };
    changeRoleBtn.addEventListener('click', onChange);
  },

  // _componentMonth(_userToken, _userHome, homeContainer, month, containerLoad) {
  //   const getMonth = localStorage.getItem('MONTH');
  //   month.children.item(getMonth).setAttribute('selected', 'selected');
  //   month.addEventListener('change', () => {
  //     localStorage.setItem('MONTH', month.value);
  //     this._insertPayment(_userToken, _userHome, homeContainer, containerLoad);
  //   });
  // },

  // _componentYear(_userToken, _userHome, homeContainer, year, containerLoad) {
  //   const getYear = localStorage.getItem('YEAR');
  //   year.childNodes.forEach((el) => ((el.value === getYear) ? el.setAttribute('selected', 'selected') : null));
  //   year.addEventListener('change', () => {
  //     localStorage.setItem('YEAR', year.value);
  //     this._insertPayment(_userToken, _userHome, homeContainer, containerLoad);
  //   });
  // },

  _componentNavPage({
    current_page, pages, _userToken, _userHome, homeContainer, containerLoad,
  }) {
    const containerNavPage = homeContainer.querySelector('#container-nav-page');
    if (pages > 1) {
      const navPage = `
        <button id="nav-prev-btn" class="flex gap-x-1">
          ${iconChevronLeft}
          <p>Prev</p>
        </button>
        <p><span id="current-page">${current_page}</span> of <span id="total-page">${pages}</span></p>
        <button id="nav-next-btn" class="flex gap-x-1">
          <p>Next</p>
          ${iconChevronRight}
        </button>
      `;
      containerNavPage.innerHTML = navPage;
      const navPrevBtn = homeContainer.querySelector('#nav-prev-btn');
      const navNextBtn = homeContainer.querySelector('#nav-next-btn');
      const currPage = Number(localStorage.getItem('PAGE'));
      if (currPage <= 1) navPrevBtn.classList.add('text-[#DCDCDC]');
      if (currPage === pages) navNextBtn.classList.add('text-[#DCDCDC]');
      navPrevBtn.addEventListener('click', () => {
        if (currPage > 1) {
          localStorage.setItem('PAGE', currPage - 1);
          this._insertPayment(_userToken, _userHome, homeContainer, homeContainer);
          location.reload();
        }
      });
      navNextBtn.addEventListener('click', () => {
        if (currPage < pages) {
          localStorage.setItem('PAGE', currPage + 1);
          this._insertPayment(_userToken, _userHome, homeContainer, homeContainer);
          location.reload();
        }
      });
    } else containerNavPage.innerHTML = '';
  },

  async _insertPayment(_userToken, _userHome, homeContainer, containerLoad) {
    const payload = {
      token: _userToken,
      // month: localStorage.getItem('MONTH'),
      // year: localStorage.getItem('YEAR'),
      reqOnly: localStorage.getItem('REQ_ONLY'),
      page: localStorage.getItem('PAGE'),
      role: _userHome,
    };
    containerLoad.innerHTML += loadPage;

    const data = await AppSource.getPayment(payload);
    // ------------------------- dummy -------------------------
    // const data = paymentDataDummy;

    containerLoad.lastElementChild.remove();
    this._insertData(data, _userToken, _userHome, homeContainer, containerLoad);
  },

  async _searchPayment(_userToken, _userHome, homeContainer, query, containerLoad) {
    const payload = {
      token: _userToken,
      query,
    };
    containerLoad.innerHTML += loadPage;

    const data = await AppSource.searchPayment(payload);
    // ------------------------- dummy -------------------------
    // const data = paymentDataDummy;

    containerLoad.lastElementChild.remove();
    data.total = 0;
    this._insertData(data, _userToken, _userHome, homeContainer, containerLoad);
  },

  _insertData({
    error, message, current_page, pages, data, total,
  }, _userToken, _userHome, homeContainer, containerLoad) {
    const containerPayments = homeContainer.querySelector('#container-payments');
    containerPayments.innerHTML = '';
    if (!error) {
      for (let i = 0; i < data.length; i++) {
        const {
          id, username, nominal, request_on, status, accepted_on, officer_name,
        } = data[i];
        const rupiah = formatRupiah(nominal);
        const currPage = Number(localStorage.getItem('PAGE'));
        const countPayment = (total - i) - Number(`${currPage - 1}0`);
        if (status === '1') {
          if (_userHome === '1') containerPayments.innerHTML += cardAccPayment(`payment-${id}`, `Payment-${countPayment}`, rupiah, request_on, accepted_on, officer_name);
          else containerPayments.innerHTML += cardAccPayment(`payment-${id}`, username, rupiah, request_on, accepted_on, officer_name);
        } else if (status === '0') {
          if (_userHome === '1') containerPayments.innerHTML += cardReqPayment(`payment-${id}`, `Payment-${countPayment}`, rupiah, request_on);
          else containerPayments.innerHTML += cardReqPayment(`payment-${id}`, username, rupiah, request_on);
          this._disabledReqButton(_userHome, homeContainer);
        }
      }

      // data.forEach(({
      //   id, username, nominal, request_on, status, accepted_on, officer_name,
      // }) => {
      //   const rupiah = formatRupiah(nominal);
      //   if (status) containerPayments.innerHTML += cardAccPayment(`payment-${id}`, username, rupiah, request_on, accepted_on, officer_name);
      //   else {
      //     containerPayments.innerHTML += cardReqPayment(`payment-${id}`, username, rupiah, request_on);
      //     this._disabledReqButton(_userHome, homeContainer);
      //   }
      // });

      containerPayments.querySelectorAll('.card-payment').forEach((card) => {
        const id = card.getAttribute('id').split('-')[1];
        card.addEventListener('click', () => location.assign(`#/detail-payment/${id}`));
      });
    } else if (message === 401) this._error(homeContainer);
    else containerPayments.innerHTML = `<p class="text-center underline mt-20">${message}</p>`;
    this._componentNavPage({
      current_page, pages, _userToken, _userHome, homeContainer, containerLoad,
    });
  },

  _error(homeContainer) {
    homeContainer.innerHTML = '<error-page></error-page>';
    const backHomeBtn = homeContainer.querySelector('#back-home-btn');
    backHomeBtn.textContent = 'Login';
    backHomeBtn.addEventListener('click', () => location.assign('#/login'));
  },
};

export default HomeHandler;
